import hoistNonReactStatics from 'hoist-non-react-statics'
import { useRouter } from 'next/router'
import {
  AuthAction,
  PageURL,
  useAuthUser,
  withAuthUser,
} from 'next-firebase-auth'
import { ComponentType, useEffect, useMemo } from 'react'
import { InternalPassedProps } from 'src/auth/withAuthSSR'
import Hotjar from 'src/components/Hotjar'
import { AuthProvider } from 'src/contexts/AuthContext'
import { Team, UserProfile } from 'src/types'
import { getFromSerialized } from 'src/utils/serializable'

import { Account } from './Account'
import { useCombinedTeam } from './useCombinedTeam'
import { useCombinedUser } from './useCombinedUser'
import { useCombinedUserProfile } from './useCombinedUserProfile'

type WithAuthUserOptions = {
  whenAuthed?: AuthAction.RENDER | AuthAction.REDIRECT_TO_APP
  whenAuthedBeforeRedirect?:
    | AuthAction.RENDER
    | AuthAction.SHOW_LOADER
    | AuthAction.RETURN_NULL
  whenUnauthedBeforeInit?:
    | AuthAction.RENDER
    | AuthAction.REDIRECT_TO_LOGIN
    | AuthAction.SHOW_LOADER
    | AuthAction.RETURN_NULL
  whenUnauthedAfterInit?: AuthAction.RENDER | AuthAction.REDIRECT_TO_LOGIN
  appPageURL?: PageURL
  authPageURL?: PageURL
  LoaderComponent?: ComponentType | null
  allowUnverifiedEmail?: boolean
}

/**
 * HOC that extends withAuthUser and loads the User in memory
 * For convenience, we default the next-firebase-auth options to:
 * - whenUnauthed(Before|After)Init: REDIRECT_TO_LOGIN
 */

type T<ComponentProps> = (
  component: ComponentType<ComponentProps>
) => ComponentType<ComponentProps>

export default function withAuth<ComponentProps extends object>(
  options?: WithAuthUserOptions
): T<ComponentProps> {
  return (ChildComponent: ComponentType<ComponentProps>) => {
    const WithAuthHOC = (props: ComponentProps & InternalPassedProps) => {
      const router = useRouter()
      const authUser = useAuthUser()

      const {
        _serializableUserProfile,
        _serializableTeam,
        _algoliaApiKey,
        ...otherProps
      } = props

      const userProfileFromSSR = _serializableUserProfile
        ? getFromSerialized<UserProfile>(_serializableUserProfile)
        : null

      const userProfile = useCombinedUserProfile(userProfileFromSSR)
      const user = useCombinedUser(userProfile)

      const teamFromSSR = _serializableTeam
        ? getFromSerialized<Team>(_serializableTeam)
        : user.activeTeamId
        ? ({ id: user.activeTeamId } as Team)
        : null

      const team = useCombinedTeam(teamFromSSR)

      const account = useMemo(
        () => new Account(authUser, userProfile, team),
        [authUser, userProfile, team]
      )

      useEffect(() => {
        if (options?.allowUnverifiedEmail) {
          return
        }

        if (account.isLoggedIn && !account.userEmailVerified) {
          router.push(
            `/email-verification?redirect=${encodeURIComponent(router.asPath)}`
          )
        }
      }, [account, router])

      return (
        <AuthProvider
          user={user}
          team={team}
          account={account}
          algoliaApiKey={_algoliaApiKey || undefined}
        >
          <ChildComponent {...(otherProps as ComponentProps)} />
          {/* Hotjar needs to be instantiated here because it needs useAccount */}
          <Hotjar />
        </AuthProvider>
      )
    }
    WithAuthHOC.displayName = 'WithAuthHOC'
    hoistNonReactStatics(WithAuthHOC, ChildComponent)

    return withAuthUser<ComponentProps>({
      whenUnauthedBeforeInit: AuthAction.RETURN_NULL,
      whenUnauthedAfterInit: AuthAction.REDIRECT_TO_LOGIN,
      ...(options ? options : {}),
    })(WithAuthHOC)
  }
}
